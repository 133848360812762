import React from "react";
import "./CourseLesson.css";


const CourseLesson2 = () => {
  return (
    <div className="course__lesson">
        <div className="section__box html__color">
        <h1 className="section__title">Section 1: Basics</h1>
      </div>
      <h1 className="lesson__title">HTML Practice</h1>
      <p className="lesson__para">
        We will get you practicing your HTML skills in this lesson. Writing code
        yourself is the absolute BEST way to learn.
      </p>
      <div className="video__frame html">
      <iframe
     className="video"
     width="600"
     height="600"
     src="https://www.youtube.com/embed/PBKbq3yHTks"
     title="YouTube video player"
     frameborder="0"
     allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
     allowfullscreen
   ></iframe>
      </div>
      <div className="buttons__container">
        <a className="btn__wrapper" href="/section-1/lesson-3">
          <button className="html__btn next__lesson--btn">Proceed to Lesson 3</button>
        </a>
        <a className="btn__wrapper" target="_blank" href="https://discord.gg/P8hxsbzsKS">
          <button className="help__btn next__lesson--btn">Need Help?</button>
        </a>
      </div>
    </div>
  );
};

export default CourseLesson2;
