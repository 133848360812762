import React from 'react'
import "./CourseLesson.css";
import { UserAuth } from "../context/AuthContext";
import LockIcon from "@mui/icons-material/Lock";


const PracticeCSS = () => {
  const { userIsPremium } = UserAuth();
  return (
    <div className="course__lesson">
      <div className="section__box css__color">
        <h1 className="section__title">Section 2: Stylization</h1>
      </div>
      <h1 className="lesson__title">How to Practice CSS</h1>
      <p className="lesson__para">
        Now you learn the best way to practice your CSS knowledge and build a diverse skillset.
      </p>
      <div className="video__frame css">
      {userIsPremium && (
           <iframe
           className="video"
             width="600"
             height="600"
             src="https://www.youtube.com/embed/xMPERDjcTeU"
             title="YouTube video player"
             frameborder="0"
             allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
             allowfullscreen
           ></iframe>
        )}
        {!userIsPremium && (
          <div className="video">
            <img
              className="video"
              src="https://i.ytimg.com/vi/tNJQY1tP5mY/maxresdefault.jpg"
              alt=""
            />
            <LockIcon className="lock pre" />
            <h2 className="lock__text">
              Upgrade to a <span className="pre">Premium</span> account to access this content
            </h2>
          </div>
        )}
      </div>
      <div className="buttons__container">
        <a className=" btn__wrapper" href="/section-2/lesson-6">
          <button className="css__btn next__lesson--btn">Proceed to Lesson 6</button>
        </a>
        <a className="btn__wrapper" target="_blank" href="https://discord.gg/P8hxsbzsKS">
          <button className="help__btn next__lesson--btn">Need Help?</button>
        </a>
      </div>
    </div>
  );
};

export default PracticeCSS
