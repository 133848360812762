import React from "react";
import "./CourseLesson.css";
import MessageTemplate from "../assets/message-tips.pdf";
import { UserAuth } from "../context/AuthContext";
import LockIcon from "@mui/icons-material/Lock";

const CSSLesson4 = () => {
  const { userIsPremium } = UserAuth();

  return (
    <div className="course__lesson">
      <div className="section__box css__color">
        <h1 className="section__title">Section 2: Stylization</h1>
      </div>
      <h1 className="lesson__title">Start Making Money</h1>
      <p className="lesson__para">
        You now have the skills to start earning money building websites! Whilst you will be earning money, you are also building credibility as you are creating real world projects! Attached below is a message strategy template for you to use!
      </p>
      {userIsPremium && 
        <a href={MessageTemplate} target="_blank" className="asset">
        Message Strategy
      </a>
      }
      
      <div className="video__frame css">
        {userIsPremium && (
           <iframe
           className="video"
             width="600"
             height="600"
             src="https://www.youtube.com/embed/S3O2lPnrre4"
             title="YouTube video player"
             frameborder="0"
             allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
             allowfullscreen
           ></iframe>
        )}
        {!userIsPremium && (
          <div className="video">
            <img
              className="video"
              src="https://i.ytimg.com/vi/tNJQY1tP5mY/maxresdefault.jpg"
              alt=""
            />
            <LockIcon className="lock pre" />
            <h2 className="lock__text">
              Upgrade to a <span className="pre">Premium</span> account to access this content
            </h2>
          </div>
        )}
      </div>
      <div className="buttons__container">
      <a className="btn__wrapper" href="/section-3/lesson-1">
          <button className="ui__btn next__lesson--btn">Proceed to Section 3: UI Design</button>
        </a>
        <a className="btn__wrapper" target="_blank" href="https://discord.gg/P8hxsbzsKS">
          <button className="help__btn next__lesson--btn">Need Help?</button>
        </a>
        </div>
    </div>
  );
};

export default CSSLesson4;
