import { QuerySnapshot } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import "./PlanScreen.css";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PlanScreen = ({ modal, setModal, planID }) => {
  const [products, setProducts] = useState([]);
  const [planScreenLoader, setPlanScreenLoader] = useState(false);
  //   useEffect(() => {
  //    db.collection('products').where('active', '==', true)
  //     .get().then((QuerySnapshot) => {
  //         const products = {};
  //         QuerySnapshot.forEach(async productDoc => {
  //             products[productDoc.id] = productDoc.data();
  //             const priceSnap = await productDoc.ref.collection('prices')
  //             .get();
  //             priceSnap.docs.forEach(price => {
  //                 products[productDoc.id].prices = {
  //                     priceId: price.id,
  //                     priceData: price.data()
  //                 }
  //             })
  //         });
  //         setProducts(products)
  //     })
  //   }, [])

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const { user, signUp } = UserAuth();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setPlanScreenLoader(true);
      const for_premium = planID;
      await signUp(email, password, firstName, lastName, for_premium, planScreenLoader, setPlanScreenLoader);
      //navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="planscreen-warp">
      <div className="planscreen">
        <div className="modal__left">
          <span
            onClick={() => {
              setModal(false);
            }}
          >
            <i className="bi-x"></i>
          </span>
          <h1 className="modal__title">Premium Plan</h1>

          <form onSubmit={handleSubmit}>
            <div className="modal__form">
              <div className="form__item">
                <label className="form__item--label">First Name</label>
                <input
                  className="input"
                  onChange={(e) => setFirstName(e.target.value)}
                  name="firstName"
                  type="text"
                  placeholder="Enter your first name"
                  required
                />
              </div>
              <div className="form__item">
                <label className="form__item--label">Last Name</label>
                <input
                  className="input"
                  onChange={(e) => setLastName(e.target.value)}
                  name="lastName"
                  type="text"
                  placeholder="Enter your last name"
                  required
                />
              </div>

              <div className="form__item">
                <label className="form__item--label">Email</label>
                <input
                  className="input"
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  type="email"
                  placeholder="Create your email address"
                  required
                />
              </div>
              <div className="form__item">
                <label className="form__item--label">Password</label>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  className="input"
                  name="user_password"
                  type="password"
                  placeholder="Create a password"
                  required
                />
              </div>
              <div className="modal__btn--wrapper">
                <button id="form__submit" className="form__submit">
                  Proceed
                </button>
              </div>
            </div>
          </form>
        </div>
        {/* <div className="modal__right">
          <p className="modal__para">
            Premium features include:
          </p>
          <ul className="modal__list">
            <div className="df">
            <CheckCircleIcon className="sm" />
            <li className="modal__li"> Unlock the full course</li>
            </div>
            <div className="df">
            <CheckCircleIcon className="sm" />
            <li className="modal__li"> 1:1 Mentoring</li>
            </div>
            <div className="df">
            <CheckCircleIcon className="sm" />
            <li className="modal__li">PC Giveaways</li>
            </div>
            <div className="df">
            <CheckCircleIcon className="sm" />
            <li className="modal__li">Certificate on completion</li>
            </div>
          </ul>
          <a href="https://discord.com/invite/P8hxsbzsKS" target="_blank">
            <div className="discord__btn">Join the Discord</div>
          </a>
        </div> */}
      </div>
      {planScreenLoader && (
        <div className="planscreen-overlay">
          <div className="planscreen-overlay-content">
            <img src="./loading.gif" />
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanScreen;
