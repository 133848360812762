import React from "react";
import "./CourseLesson.css";

const Inspect = () => {
  return (
    <div className="course__lesson">
        <div className="section__box html__color">
        <h1 className="section__title">Section 1: Basics</h1>
      </div>
      <h1 className="lesson__title">Inspect Element</h1>
      <p className="lesson__para">
        Learn about the inspect tool in your web browser, what it can do and how powerful it can be for developers!
      </p>
      <div className="video__frame html">
      <iframe
         className="video"
         width="600"
         height="600"
         src="https://www.youtube.com/embed/-LPEheGjGW0"
         title="YouTube video player"
         frameborder="0"
         allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
         allowfullscreen
       ></iframe>
      </div>
      <div className="buttons__container">
        <a className="btn__wrapper" href="/section-2/lesson-1">
          <button className="css__btn next__lesson--btn">Proceed to Section 2: Stylization</button>
        </a>
        <a className="btn__wrapper" target="_blank" href="https://discord.gg/P8hxsbzsKS">
          <button className="help__btn next__lesson--btn">Need Help?</button>
        </a>
      </div>
    </div>
  );
};

export default Inspect;