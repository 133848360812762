import React, { useEffect, useState } from "react";
import "./AccountPage.css";
import { auth, db } from "../firebase";
import { UserAuth } from "../context/AuthContext";
import LogUSerData from "../components/LogUserData";
import { doc, updateDoc } from "firebase/firestore";
import { updatePassword } from "firebase/auth";
import { createCheckoutSession } from "../stripe/createCheckoutSession";
import { Link, useNavigate } from "react-router-dom";

const AccountPage = () => {
  const [userData, setUserData] = useState([]);
  const [enableFirstLastname, setEnableFirstLastname] = useState(false);
  const [enablePassname, setEnablePassname] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [minpasswordmsg, setMinPasswordMsg] = useState(false);
  const [userUdpated, setUserUdpated] = useState(false);
  const [upgradeLoader, setUpgradeLoader] = useState(false);

  const navigate = useNavigate();

  const { user, userIsPremium } = UserAuth();

  useEffect(() => {
    if (user) {
      const checkLogUSerData = async function () {
        setUserData(await LogUSerData());
      };
      checkLogUSerData();
    }
  }, [user, userUdpated]);

  const handleUserUpdate = (e) => {
    e.preventDefault();

    const upddocRef = doc(db, "users", user.uid);
    const upduserdata = {
      firstName: firstName,
      lastName: lastName,
    };

    updateDoc(upddocRef, upduserdata)
      .then((upddocRef) => {
        setUserUdpated(true);
        setEnableFirstLastname(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUserPassUpdate = (e) => {
    e.preventDefault();

    if (password.length <= 5) {
      setMinPasswordMsg(true);
    } else {
      setMinPasswordMsg(false);
      const user = auth.currentUser;
      updatePassword(user, password)
        .then(() => {
          console.log("updated");
          setUserUdpated(true);
          setEnablePassname(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleUserUpgrade = (e) => {
    e.preventDefault();
    setUpgradeLoader(true);
    navigate("/payment", { state: { prevPath: "account", userId: user.uid } });
    // console.log("userData", userData);
    // createCheckoutSession(user.uid);
  };

  return (
    <form className="account-form">
      <div className="account__page">
        <div className="account__container">
          {userUdpated && (
            <div style={{ textAlign: "center" }}>User Updated</div>
          )}
          <h1 className="account__header">My Account</h1>
          <figure className="account__item">
            <p className="account__item--type">Subscription Status</p>
            <p className="account__item--value">
              {userIsPremium && "Premium"}
              {!userIsPremium && "Free"}
            </p>
            {/* {!userIsPremium ? (<button className="account__btn" onClick={handleUserUpgrade}>
              {upgradeLoader && <img src="./assets/images/loader1.gif" />}
              Upgrade</button>) : 
              (<a className="account__btn" href="https://billing.stripe.com/p/login/dR69CBcLwdwD4b64gg">Cancel Subscription</a>)
            } */}

            {!userIsPremium ? (
              <button className="account__btn" onClick={handleUserUpgrade}>
                {upgradeLoader && (
                  <img src="./assets/images/loader1.gif" alt="loader" />
                )}
                Upgrade
              </button>
            ) : (
              <a
                className="account__btn"
                href="https://billing.stripe.com/p/login/dR69CBcLwdwD4b64gg"
              >
                Cancel Subscription
              </a>
            )}
          </figure>
          <figure className="account__item">
            <p className="account__item--type">Email</p>
            <p className="account__item--value">{userData && userData.email}</p>
          </figure>
          <figure className="account__item">
            <p className="account__item--type">Name</p>
            <p className="account__item--value">
              {userData && !enableFirstLastname ? (
                userData.firstName + " " + userData.lastName
              ) : (
                <span className="accform-two-fields">
                  <input
                    type="text"
                    name="userFirstName"
                    placeholder="Edit First Name"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                  <input
                    type="text"
                    name="userLastName"
                    placeholder="Edit Last Name"
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </span>
              )}
            </p>
            {userData && !enableFirstLastname ? (
              <button
                className="account__btn"
                onClick={(e) => {
                  e.preventDefault();
                  setEnableFirstLastname(true);
                }}
              >
                Edit
              </button>
            ) : (
              <button className="account__btn" onClick={handleUserUpdate}>
                Update
              </button>
            )}
          </figure>
          <figure className="account__item">
            <p className="account__item--type">Password</p>
            <p className="account__item--value">
              {userData && !enablePassname ? (
                "*************"
              ) : (
                <span className="accform-two-fields">
                  <input
                    type="password"
                    name="userPassword"
                    placeholder="Edit Password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </span>
              )}
              {minpasswordmsg && (
                <small style={{ fontSize: "12px", color: "red" }}>
                  Password must be greter than or equal to 6
                </small>
              )}
            </p>
            {userData && !enablePassname ? (
              <button
                className="account__btn"
                onClick={(e) => {
                  e.preventDefault();
                  setEnablePassname(true);
                }}
              >
                Edit
              </button>
            ) : (
              <button className="account__btn" onClick={handleUserPassUpdate}>
                Update
              </button>
            )}
          </figure>
        </div>
      </div>
    </form>
  );
};

export default AccountPage;
