import React from 'react'
import "./CourseLesson.css";
import { UserAuth } from "../context/AuthContext";
import LockIcon from '@mui/icons-material/Lock';

const BeginnerJS = () => {
    const { userIsPremium } = UserAuth();
  return (
    <div className="course__lesson">
       <div className="section__box hired__color">
        <h1 className="section__title">Section 7: Get Hired</h1>
      </div>
      <h1 className="lesson__title">Job Application</h1>
      <p className="lesson__para">
       Let's set you up with a complete portfolio, excellent resume and irresistable cover letter.
      </p>
      {userIsPremium && 
        <a href='https://www.youtube.com/watch?v=hYv6BM2fWd8&list=PLd0qRSEDFQI7DgB5BKHQrryFlR02n1bK-' target="_blank" className="asset">
        Sample Portfolio
      </a>
      }
      <div className="video__frame hired">
      {userIsPremium && (
        <iframe
        className="video"
          width="600"
          height="600"
          src="https://www.youtube.com/embed/xLr98-RJ_nc"
          title="YouTube video player"
          frameborder="0"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        )}
        {!userIsPremium && (
          <div className="video">
            <img
              className="video"
              src="https://i.ytimg.com/vi/tNJQY1tP5mY/maxresdefault.jpg"
              alt=""
            />
            <LockIcon className="lock pre" />
            <h2 className="lock__text">
            Upgrade to a <span className="pre">Premium</span> account to access this content
            </h2>
          </div>
        )}
      </div>
      <div className="buttons__container">
        <a className="btn__wrapper" href="/section-8/lesson-1">
          <button className="technical__btn next__lesson--btn">
            Section 8: Technical
          </button>
        </a>
        <a
          className="btn__wrapper"
          target="_blank"
          href="https://discord.gg/P8hxsbzsKS"
        >
          <button className="help__btn next__lesson--btn">Need Help?</button>
        </a>
      </div>
    </div>
  )
}

export default BeginnerJS