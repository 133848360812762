import React from "react";
import "./CourseLesson.css";

const CourseLesson3 = () => {
  return (
    <div className="course__lesson">
        <div className="section__box html__color">
        <h1 className="section__title">Section 1: Basics</h1>
      </div>
      <h1 className="lesson__title">HTML Final Project</h1>
      <p className="lesson__para">
        This is the final section of HTML. Congratulations on making it this
        far, if you still feel lost don't worry, practice makes perfect.
      </p>
      <div className="video__frame html">
      <iframe
         className="video"
         width="600"
         height="600"
         src="https://www.youtube.com/embed/qvAHVeZf0-4"
         title="YouTube video player"
         frameborder="0"
         allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
         allowfullscreen
       ></iframe>
      </div>
      <div className="buttons__container">
        <a className="btn__wrapper" href="/section-1/lesson-4">
          <button className="html__btn next__lesson--btn">Proceed to Lesson 4</button>
        </a>
        <a className="btn__wrapper" target="_blank" href="https://discord.gg/P8hxsbzsKS">
          <button className="help__btn next__lesson--btn">Need Help?</button>
        </a>
      </div>
    </div>
  );
};

export default CourseLesson3;
