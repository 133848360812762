import { loadStripe } from "@stripe/stripe-js";

//let stripePromise: Stripe | null;
let stripePromise;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      "pk_live_51MVRkfF9GMZUpcRm6UjXU1f78XsTBD3wXTayOFUnmbv5uJj71sc6Ir4q4NfBrwJSoPNOTBAZg88cHDp2miTbzpUU00Ea0T5bQX"
    );
  }
  return stripePromise;
};
export default initializeStripe;