import React from 'react'
import './Header.css'

const Header = () => {
  return (
    <div className="header">
        <div id="scroll-container">
</div>
    </div>
  )
}

export default Header