import React from 'react'
import "./CourseLesson.css";
import { UserAuth } from "../context/AuthContext";
import LockIcon from '@mui/icons-material/Lock';

const ColorChanger = () => {

  const { userIsPremium } = UserAuth();
  return (
    <div className="course__lesson">
      <div className="section__box js__color">
        <h1 className="section__title">Section 4: Functionality</h1>
      </div>
    <h1 className="lesson__title">Build a Background Color Changer</h1>
    <p className="lesson__para">
     Here you will build your first official Javascript project!
    </p>
    <div className="video__frame js">
      {userIsPremium && (
        <iframe
          className="video"
          width="600"
          height="600"
          src="https://www.youtube.com/embed/7sTb9UbRuK0"
          title="YouTube video player"
          frameborder="0"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      )}
      {!userIsPremium && (
        <div className="video">
          <img
            className="video"
            src="https://i.ytimg.com/vi/tNJQY1tP5mY/maxresdefault.jpg"
            alt=""
          />
          <LockIcon className="lock" />
          <h2 className="lock__text pre">
          Upgrade to a <span className="pre">Premium</span> account to access this content
          </h2>
        </div>
      )}
    </div>
    <div className="buttons__container">
      <a className="btn__wrapper" href="/section-4/lesson-5">
        <button className="js__btn next__lesson--btn">
          Proceed to Lesson 5
        </button>
      </a>
      <a
        className="btn__wrapper"
        target="_blank"
        href="https://discord.gg/P8hxsbzsKS"
      >
        <button className="help__btn next__lesson--btn">Need Help?</button>
      </a>
    </div>
  </div>
  );
};

export default ColorChanger