import React from "react";
import "./CourseLesson.css";
import { UserAuth } from "../context/AuthContext";
import LockIcon from "@mui/icons-material/Lock";

const JSLesson4 = () => {
  const { userIsPremium } = UserAuth();
  return (
  
    <div className="course__lesson">
      <div className="section__box js__color">
        <h1 className="section__title">Section 4: Functionality</h1>
      </div>
    <h1 className="lesson__title">JavaScript Calculator</h1>
    <p className="lesson__para">
    This lesson you practice the JavaScript skills you just learn't. Don't
      worry if you aren't fully understanding the language, over time it will
      become more clear.
    </p>
    <div className="video__frame js">  { userIsPremium && (
         <iframe
         className="video"
           width="600"
           height="600"
           src="https://www.youtube.com/embed/IReO7ObQCLI"
           title="YouTube video player"
           frameborder="0"
           allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
           allowfullscreen
         ></iframe>
      )
      }
      { !userIsPremium && (
        <div className="video">
          <img className="video" src="https://i.ytimg.com/vi/tNJQY1tP5mY/maxresdefault.jpg" alt="" />
          <LockIcon className="lock pre" />
          <h2 className="lock__text">Upgrade to a <span className="pre">Premium</span> account to access this content</h2>
          </div>
      )
      }</div>
    <div className="buttons__container">
      <a className="btn__wrapper" href="/section-4/lesson-6">
        <button className="js__btn next__lesson--btn">
          Proceed to Lesson 6
        </button>
      </a>
      <a
        className="btn__wrapper"
        target="_blank"
        href="https://discord.gg/P8hxsbzsKS"
      >
        <button className="help__btn next__lesson--btn">Need Help?</button>
      </a>
    </div>
  </div>
  );
};

export default JSLesson4;
