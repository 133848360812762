import React, { useEffect } from "react";
import "./Home.css";
import StarIcon from "@mui/icons-material/Star";
import Logo from "../assets/mainImage.svg";
import Hello from "../assets/hello.svg";
import Project from "../assets/project.svg";
import Money from "../assets/money.svg";
import Footer from "../components/Footer";
import LaptopIcon from "@mui/icons-material/Laptop";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import VerifiedIcon from "@mui/icons-material/Verified";
import Aos from "aos";
import "aos/dist/aos.css";

const Home = ({ darkMode }) => {
  useEffect(() => {
    Aos.init();
  }, []);

  const students =
    1000 >= 1300 ? 1000 : Math.floor(Math.random() * (1300 - 1000 + 1)) + 1000;
  console.log(students);
  console.log(darkMode);
  return (
    <div className="home light">
      <div className="home__main">
        <div className="home-left">
          <figure className="text__wrapper">
            <h1 className="home-title">
              Begin your frontend{" "}
              <span className="gold">software engineer</span> career today!
            </h1>
            <p className="home-subtitle">
              Build the skillset you need to become a capable, qualified
              software engineer with project-based, stuctured learning and land
              a <span className="gold">six figure</span> job in just{" "}
              <span className="gold">nine weeks!</span>
            </p>
            <a
              target="_blank"
              className="discord-home nav__link--btn top__nav--link"
              href="https://discord.gg/P8hxsbzsKS"
            >
              <img
                src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6cc3c481a15a141738_icon_clyde_white_RGB.png"
                className="discord__logo"
                alt=""
              />
              <p className="disc">Discord</p>
            </a>
            <div className="btns__wrapper">
              <a href="/courseintro">
                <button className="get__started--btn" id="button">
                  Introduction
                </button>
              </a>
              <a href="/payment">
                <button className="btn2 get__started--btn">Sign Up</button>
              </a>
            </div>
            <figure className="star__wrapper">
              {/* <div className="stars">
                <StarIcon className="star__icon" />
                <StarIcon className="star__icon" />
                <StarIcon className="star__icon" />
                <StarIcon className="star__icon" />
                <StarIcon className="star__icon" />
              </div> */}

              {/* <figure className="active__wrapper">
                <img
                  src="https://icon-library.com/images/online-status-icon/online-status-icon-25.jpg"
                  className="verified__icon"
                  alt=""
                />
                <i className="active__students">{students} Currently Active Students</i>
              </figure> */}
            </figure>
          </figure>
        </div>
        <div className="home-right">
          <img
            className="home-img"
            src="https://i.pinimg.com/originals/9a/68/71/9a68716efc331fcc84e3a4ce5f23d18d.png"
            alt=""
          />
          {/* <div className="requirements__container">
          <div className="req-top">
          <figure className="req__figure">
              <TrendingUpIcon className="req__icon" />
              <div className="figure__inner">
                <h5 className="requirement__title">Skill Level:</h5>
                <h5 className="requirement__value">Beginner</h5>
              </div>
            </figure>
            <figure className="req__figure">
              <AttachMoneyIcon className="req__icon" />
              <div className="figure__inner">
                <h5 className="requirement__title">Average Salary:</h5>
                <h5 className="requirement__value">$119,061</h5>
              </div>
            </figure>
          </div>
            
          <div className="req-bottom">
          <figure className="req__figure">
              <SchoolIcon className="req__icon" />
              <div className="figure__inner">
                <h5 className="requirement__title">Average Completion Time:</h5>
                <h5 className="requirement__value">8 Weeks</h5>
              </div>
            </figure>
            <figure className="req__figure">
              <WorkIcon className="req__icon" />
              <div className="figure__inner">
                <h5 className="requirement__title">Current Openings:</h5>
                <h5 className="requirement__value">218,350</h5>
              </div>
            </figure>
          </div>
            
          </div> */}
        </div>
      </div>

      <div className="main">
        <hr />
        {/* <div data-aos="zoom-in" className="testimonials">
          <h1 className="testimonials-header">
            Testimonials from Past Students
          </h1>
          <div className="test-wrapper">
            <div className="test-container">
              <h4 className="test-title">$75,000 Junior Role</h4>
              <p className="test-para">
                {" "}
                I contacted Zak after finding him on Tik Tok when he first
                started making videos and I asked him to give me a structured
                roadmap on how to learn. Not only did he give me a roadmap but
                he also tutored me as well as answered any questions or issues I
                had. I recently had a look at learnfrontend and it is the exact
                same roadmap with the same projects that I had on my portfolio
                that landed me a job only a few days after starting the
                application process!
              </p>
              <h4 className="test-name">- Adrian, 2023 Graduate.</h4>
            </div>
            <div className="test-container">
              <h4 className="test-title">$170,000 Netflix Role</h4>
              <p className="test-para">
                LearnFrontend is an unbelievably good, efficient resource. Zak
                teaches only what you need to know. There’s so much bs when it
                comes to code that you don’t need to know, so learning only what
                you absolutely NEED is crucial.
              </p>
              <h4 className="test-name">- Jack, 2023 Graduate.</h4>
            </div>
            <div className="test-container">
              <h4 className="test-title">$90,000 React Role</h4>
              <p className="test-para">
                Initially I was a bit skeptical finding LF on TikTok but I
                decided to give it a shot and it was the best decision of my
                life! Proud to be one of the first of many testimonials to come!
              </p>
              <h4 className="test-name">- Nathan, 2023 Graduate.</h4>
            </div>
          </div>
        </div> */}

        <h1 className="main__title" data-aos="zoom-in" data-aos-duration="1000">
          Course Contents & Mission
        </h1>
        <div
          className="main__content"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div className="main__content--left">
            <h1 className="main__content--title">Our Mission</h1>
            <p className="main__content--para">
              Our mission is to empower a multitude of aspiring web developers
              to achieve success beyond their expectations! We are dedicated to
              imparting a comprehensive skillset that transforms individuals
              into proficient and job-ready developers, all without the
              constraints of a traditional university degree or the intensity of
              a coding bootcamp.
            </p>
          </div>
          <div className="main__content--right">
            <img className="main__content--img" src={Hello} alt="" />
          </div>
        </div>
        <hr className="hr3" />
        <div
          className="main__content"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <div className="main__content--left">
            <img className="main__content--img" src={Project} alt="" />
          </div>
          <div className="main__content--right">
            <h1 className="main__content--title">Project-Based Learning</h1>
            <p className="main__content--para">
              Every seasoned developer recognizes that the most effective method
              for rapid coding proficiency is hands-on application! In this
              course, you'll engage in building diverse projects that not only
              enhance your skillset but also serve as tangible achievements on
              your final resume.
            </p>
          </div>
        </div>
        <hr className="hr3" />
        <div
          className="main__content"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div className="main__content--left">
            <h1 className="main__content--title">Cost Friendly</h1>
            <p className="main__content--para">
              Coding bootcamps typically come with a hefty price tag, often
              falling within the range of $10,000 to $15,000 – quite
              astonishing, isn't it? At our platform, we provide comparable
              education at a mere 0.4% of the cost! We firmly believe in
              democratizing online learning, making it accessible to every
              aspiring developer, irrespective of their financial circumstances.
            </p>
          </div>
          <div className="main__content--right">
            <img className="main__content--img" src={Money} alt="" />
          </div>
        </div>
      </div>

      <div className="community" data-aos="fade-up" data-aos-duration="1000">
        <hr className="hr2" />
        <h1 className="community__title" data-aos="zoom-in">
          Community & Feedback
        </h1>
        <div className="community__wrapper">
          <div className="community__bubble" data-aos="fade-left">
            <h2 className="community__subtitle">Discord Server</h2>
            <p className="community__para">
              This is where students come to hang out, assist each other with
              learning and seek overall support from the team at LearnFrontend.
              We've created a safe, highly moderated space for developers to
              chill out, relax and learn together. Premium students also access
              exclusive channels within the server, where they will be
              prioritized in support.
            </p>
          </div>
          <div
            className="community__bubble"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <h2 className="community__subtitle">1:1 Mentoring</h2>
            <p className="community__para">
              We strive to assist each student's learning as best possible, so
              we offer on-going mentorship with our premium users. The ability
              to speak to a dev at LearnFrontend directly allows the student to
              gain a better understanding of the code problem at hand.
            </p>
          </div>
          <div
            className="community__bubble"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <h2 className="community__subtitle">Time of Completion</h2>
            <p className="community__para">
              Students have had various completion times. Some students finish
              the course in 6 weeks, others take a few months. There is no
              specific time of completion, it is entirely dependant on the
              student. The only reccommendation we give is that each student
              takes as long as they need to complete the course, whilst
              understanding the concepts before continuing.
            </p>
          </div>
          <div
            className="community__bubble"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <h2 className="community__subtitle">Giveaways & Prizes</h2>
            <p className="community__para">
              Within the LearnFrontend Discord Server we provide frequent
              giveaways and prizes as an incentive to push through your
              programming journey! Premium members will have entries into
              giveaways to win prizes such as a 2023 Macbook Pro, Playstation 5,
              Premium Keyboards & more! Coding-based competitions also give
              members the opportunity to win prizes such as Uber Eats Vouchers,
              Steam Gift Cards & more!
            </p>
          </div>
        </div>
      </div>
      <div className="premium__ad" data-aos="fade-in" data-aos-duration="1000">
        <p className="premium__subtitle">Begin your coding journey today!</p>
        <h1 className="premium__title">Become a Premium Member</h1>

        <a className="premium__btn--wrapper" href="/payment">
          <button className="premo__btn">Create Premium Account</button>
        </a>
      </div>
    </div>
  );
};

export default Home;
