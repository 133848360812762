import React from "react";
import "./CourseIntro.css";

const CourseIntro = () => {
  return (
    <div className="course__intro set-height">
      <div className="course__intro--container">
        <h1 className="course__intro--title">
          Welcome to Learn<span className="gold">Frontend</span>
        </h1>
        <p className="course__intro--para">
          Congratulations on starting your coding journey! You will now be on
          your way to making a <b>high</b> income
          and developing an <b>extensive programming skillset </b>
          in no time. Watch the following video to begin the course!
        </p>
        <div className="video__frame into">
        <iframe
          className="video"
          width="600"
          height="600"
          src="https://www.youtube.com/embed/Tk-zKAjXSnk"
          title="YouTube video player"
          frameborder="0"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        </div>
        <a className="btn__wrapper" href="/section-1/lesson-1">
          <button className="html__btn next__lesson--btn">
            Proceed to Section 1: HTML
          </button>
        </a>
      </div>
    </div>
  );
};

export default CourseIntro;
