import React, { useEffect, useState } from "react";
import "./Payment.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PlanScreen from "../components/PlanScreen";
import { useLocation } from "react-router-dom";
import { createCheckoutSession } from "../stripe/createCheckoutSession";

const priceTable1 = [
  {
    id: 1,
    text: "Full Course",
  },
  {
    id: 2,
    text: "24/7 Mentorship & Support",
  },
  {
    id: 3,
    text: "Premium Discord membership",
  },
  {
    id: 4,
    text: "Access to 2024 Hiring Program",
  },
];

const priceTable2 = [
  {
    id: 1,
    text: "Everything from Monthly",
  },
  {
    id: 2,
    text: "One time payment only",
  },
  {
    id: 3,
    text: "Buy once, own forever!",
  },
];

const Payment = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [modal, setModal] = useState(false);
  const [planID, setPlanID] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const handlePlan = (planId) => {
    if (location?.state) {
      setBtnLoader(planId);
      createCheckoutSession(location.state.userId, planId);
    } else {
      setModal(!modal);
      setPlanID(planId);
    }
  };

  return (
    <div className="payment">
      <div className={`payment_wrapper ${modal ? "blur" : ""}`}>
        <div className="payment_col">
          <div>
            <div className="payment_head">
              <h3 className="plan_title">Monthly</h3>
              <p className="plan_para">
                Monthly is perfect for students who want a taste of what it's
                like to become a web developer.
              </p>
            </div>

            <div className="payment_body">
              <ul className="plan_features">
                {priceTable1.map((item) => (
                  <li key={item.id}>
                    <CheckCircleIcon />
                    <span>{item.text}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="payment_foot">
              <h2 className="plan_amount">
                $29.99 <small>/ monthly</small>
              </h2>
              <div className="plan_btn_wrapper">
                <button
                  type="button"
                  className="plan_btn"
                  onClick={() => handlePlan(1)}
                >
                  {btnLoader === 1 ? (
                    <>
                      <span>Please wait</span>
                      <img
                        src="./assets/images/loader1.gif"
                        alt="loader"
                        style={{
                          width: "20px",
                          height: "20px",
                          verticalAlign: "middle",
                          marginLeft: "5px",
                        }}
                      />
                    </>
                  ) : (
                    "Buy Monthly"
                  )}
                </button>
              </div>
              <div className="plan_offer"></div>
            </div>
          </div>
        </div>

        <div className="payment_col">
          <div>
            <div className="payment_head">
              <h3 className="plan_title">Lifetime</h3>
              <p className="plan_para">
                Lifetime is ideal for students who want value for money. All
                lifetime students have permanant access to mentorship as well as
                the full course.
              </p>
            </div>

            <div className="payment_body">
              <ul className="plan_features">
                {priceTable2.map((item) => (
                  <li key={item.id}>
                    <CheckCircleIcon />
                    <span>{item.text}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="payment_foot">
              <h2 className="plan_amount">
                $79.99 <small>/ lifetime</small>
              </h2>
              <div className="plan_btn_wrapper">
                <button className="plan_btn" onClick={() => handlePlan(2)}>
                  {btnLoader === 2 ? (
                    <>
                      <span>Please wait</span>
                      <img
                        src="./assets/images/loader1.gif"
                        alt="loader"
                        style={{
                          width: "20px",
                          height: "20px",
                          verticalAlign: "middle",
                          marginLeft: "5px",
                        }}
                      />
                    </>
                  ) : (
                    "Buy Lifetime"
                  )}
                </button>
              </div>
              <div className="plan_offer"></div>
            </div>
          </div>
        </div>
      </div>

      {modal && (
        <PlanScreen modal={modal} setModal={setModal} planID={planID} />
      )}
    </div>
  );
};

export default Payment;
