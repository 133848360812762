import React from 'react'
import "./CourseLesson.css";
import { UserAuth } from "../context/AuthContext";
import LockIcon from "@mui/icons-material/Lock";


const InterviewTips = () => {
  const { userIsPremium } = UserAuth();
  return (
    <div className="course__lesson">
      <div className="section__box hired__color">
        <h1 className="section__title">Section 7: Get Hired</h1>
      </div>
      <h1 className="lesson__title">React Revision</h1>
      <p className="lesson__para">
      Let's refresh your core React knowledge, going over the key concepts and questions of the library.
      </p>
      <div className="video__frame hired">
      {userIsPremium && (
         <iframe
         className="video"
         width="600"
         height="600"
         src="https://www.youtube.com/embed/HuXNdLrjMlg"
         title="YouTube video player"
         frameborder="0"
         allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
         allowfullscreen
       ></iframe>
        )}
        {!userIsPremium && (
          <div className="video">
            <img
              className="video"
              src="https://i.ytimg.com/vi/tNJQY1tP5mY/maxresdefault.jpg"
              alt=""
            />
            <LockIcon className="lock pre" />
            <h2 className="lock__text">
            Upgrade to a <span className="pre">Premium</span> account to access this content
            </h2>
          </div>
        )}
      </div>
      <div className="buttons__container">
        <a className="btn__wrapper" href="/section-7/lesson-3">
          <button className="hired__btn next__lesson--btn">
            Proceed to Lesson 3
          </button>
        </a>
        <a
          className="btn__wrapper"
          target="_blank"
          href="https://discord.gg/P8hxsbzsKS"
        >
          <button className="help__btn next__lesson--btn">Need Help?</button>
        </a>
      </div>
    </div>
  )
}

export default InterviewTips