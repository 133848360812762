import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import CourseIntro from "./pages/CourseIntro";
import CourseLesson from "./pages/CourseLesson";
import CourseLesson2 from "./pages/CourseLesson2";
import CourseLesson3 from "./pages/CourseLesson3";
import CSSLesson1 from "./pages/CSSLesson1";
import CSSLesson2 from "./pages/CSSLesson2";
import CSSLesson3 from "./pages/CSSLesson3";
import CSSLesson4 from "./pages/CSSLesson4";
import JSLesson1 from "./pages/JSLesson1";
import JSLesson2 from "./pages/JSLesson2";
import JSLesson3 from "./pages/JSLesson3";
import Auth from "./pages/Auth";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import app from "./firebase";
import SignIn from "./pages/SignIn";
import Sidebar from "./components/Sidebar";
import { AuthContextProvider } from "./context/AuthContext";
import AccountPage from "./pages/AccountPage";
import ProtectedRoute from "./components/ProtectedRoute";
import ReactLesson1 from "./pages/ReactLesson1";
import ReactLesson2 from "./pages/ReactLesson2";
import ReactLesson3 from "./pages/ReactLesson3";
import ReactLesson4 from "./pages/ReactLesson4";
import ReactLesson5 from "./pages/ReactLesson5";
import ReactLesson6 from "./pages/ReactLesson6";
import JSLesson4 from "./pages/JSLesson4";
import Resume from "./pages/Resume";
import InterviewTips from "./pages/InterviewTips";
import JobApplication from "./pages/JobApplication";
import BeginnerJS from "./pages/BeginnerJS";
import IntermediateJS from "./pages/IntermediateJS";
import ExpertJS from "./pages/ExpertJS";
import ReactTrivia from "./pages/ReactTrivia";
import DSA from "./pages/DSA";
import UILesson1 from "./pages/UILesson1";
import UILesson2 from "./pages/UILesson2";
import UILesson3 from "./pages/UILesson3";
import UILesson4 from "./pages/UILesson4";
import APILesson1 from "./pages/APILesson1";
import APILesson2 from "./pages/APILesson2";
import APILesson3 from "./pages/APILesson3";
import APILesson4 from "./pages/APILesson4";
import EasyJS from "./pages/EasyJS";
import MediumJS from "./pages/MediumJS";
import HardJS from "./pages/HardJS";
import Leetcode from "./pages/Leetcode";
import JSLesson5 from "./pages/JSLesson5";
import Inspect from "./pages/Inspect";
import Icons from "./pages/Icons";
import Header from "./components/Header";
import GroceryList from "./pages/GroceryList";
import PracticeCSS from "./pages/PracticeCSS";
import ColorChanger from "./pages/ColorChanger";
import WeightConverter from "./pages/WeightConverter";
import UILesson5 from "./pages/UILesson5";
import Payment from "./pages/Payment";

function App() {
  // const [darkMode, setDarkMode] = useState("light");
  let toggleSwitch = document.querySelector(".toggleSwitch");
  document.body.classList = "light";

  /*
    const toggleTheme = () => {
    localStorage.setItem("theme", darkMode);
    if (localStorage.getItem("theme") == "light") {
      setDarkMode("dark");
      localStorage.setItem("theme", "dark");
      console.log("i am in dark mode")
      document.body.className = darkMode;

    } else if (localStorage.getItem("theme") == "dark") {
      setDarkMode("light");
      localStorage.setItem("theme", "light");
      console.log("i am in light mode")
      document.body.className = darkMode;



    }
  };
  
  */

  return (
    <div className="app light">
      <AuthContextProvider>
        <Header />
        <Navbar />
        <Sidebar />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/sign-in" element={<SignIn />}></Route>
          {/* <Route path="/auth" element={<Auth />}></Route> */}
          {/* HTML */}
          <Route path="/courseintro" element={<CourseIntro />}></Route>
          <Route path="/section-1/lesson-1" element={<CourseLesson />}></Route>
          <Route path="/section-1/lesson-2" element={<CourseLesson2 />}></Route>
          <Route path="/section-1/lesson-3" element={<CourseLesson3 />}></Route>
          <Route path="/section-1/lesson-4" element={<Inspect />}></Route>
          {/* CSS */}
          <Route path="/section-2/lesson-1" element={<CSSLesson1 />}></Route>
          <Route path="/section-2/lesson-2" element={<CSSLesson2 />}></Route>
          <Route path="/section-2/lesson-3" element={<Icons />}></Route>
          <Route path="/section-2/lesson-4" element={<CSSLesson3 />}></Route>
          <Route path="/section-2/lesson-5" element={<PracticeCSS />}></Route>
          <Route path="/section-2/lesson-6" element={<CSSLesson4 />}></Route>
          {/* UI DESIGN */}
          <Route path="/section-3/lesson-1" element={<UILesson1 />}></Route>
          <Route path="/section-3/lesson-2" element={<UILesson2 />}></Route>
          <Route path="/section-3/lesson-3" element={<UILesson3 />}></Route>
          <Route path="/section-3/lesson-4" element={<UILesson4 />}></Route>
          <Route path="/section-3/lesson-5" element={<UILesson5 />}></Route>
          {/* JAVASCRIPT */}
          <Route path="/section-4/lesson-1" element={<JSLesson1 />}></Route>
          <Route path="/section-4/lesson-2" element={<JSLesson2 />}></Route>
          <Route path="/section-4/lesson-3" element={<JSLesson3 />}></Route>
          <Route path="/section-4/lesson-4" element={<ColorChanger />}></Route>
          <Route path="/section-4/lesson-5" element={<JSLesson4 />}></Route>
          <Route path="/section-4/lesson-6" element={<GroceryList />}></Route>
          <Route
            path="/section-4/lesson-7"
            element={<WeightConverter />}
          ></Route>
          <Route path="/section-4/lesson-8" element={<JSLesson5 />}></Route>
          {/* APIS */}
          <Route path="/section-5/lesson-1" element={<APILesson1 />}></Route>
          <Route path="/section-5/lesson-2" element={<APILesson2 />}></Route>
          <Route path="/section-5/lesson-3" element={<APILesson3 />}></Route>
          <Route path="/section-5/lesson-4" element={<APILesson4 />}></Route>
          {/* REACT */}
          <Route path="/section-6/lesson-1" element={<ReactLesson1 />}></Route>
          <Route path="/section-6/lesson-2" element={<ReactLesson2 />}></Route>
          <Route path="/section-6/lesson-3" element={<ReactLesson3 />}></Route>
          <Route path="/section-6/lesson-4" element={<ReactLesson4 />}></Route>
          <Route path="/section-6/lesson-5" element={<ReactLesson5 />}></Route>
          <Route path="/section-6/lesson-6" element={<ReactLesson6 />}></Route>
          {/* GET HIRED */}
          <Route path="/section-7/lesson-1" element={<Resume />}></Route>
          <Route path="/section-7/lesson-2" element={<InterviewTips />}></Route>
          <Route
            path="/section-7/lesson-3"
            element={<JobApplication />}
          ></Route>
          <Route path="/section-7/lesson-4" element={<BeginnerJS />}></Route>
          {/* TECHNICAL */}
          <Route path="/section-8/lesson-1" element={<EasyJS />}></Route>
          <Route path="/section-8/lesson-2" element={<MediumJS />}></Route>
          <Route path="/section-8/lesson-3" element={<HardJS />}></Route>
          <Route path="/section-8/lesson-4" element={<Leetcode />}></Route>
          <Route
            path="/account"
            element={
              <ProtectedRoute>
                <AccountPage />
              </ProtectedRoute>
            }
          />
          {/* PAYMENT */}
          <Route path="/payment" element={<Payment />}></Route>
        </Routes>
      </AuthContextProvider>
    </div>
  );
}

export default App;
