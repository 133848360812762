import React from "react";
import "./CourseLesson.css";
import { UserAuth } from "../context/AuthContext";
import LockIcon from "@mui/icons-material/Lock";

const ReactLesson3 = () => {
  const { userIsPremium } = UserAuth();
  return (
    <div className="course__lesson">
      <div className="section__box react__color">
        <h1 className="section__title">Section 6: React</h1>
      </div>
      <h1 className="lesson__title">Shopping Cart App</h1>
      <p className="lesson__para">
        Apply your newly learned React skills by building a Shopping Cart app.
      </p>
      <div className="video__frame react">
      {userIsPremium && (
          <iframe
            className="video"
            width="600"
            height="600"
            src="https://www.youtube.com/embed/tEMrD9t85v4?si=F9UoB7rsRWy3pN2m"
            title="YouTube video player"
            frameborder="0"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        )}
        {!userIsPremium && (
          <div className="video">
            <img
              className="video"
              src="https://i.ytimg.com/vi/tNJQY1tP5mY/maxresdefault.jpg"
              alt=""
            />
            <LockIcon className="lock pre" />
            <h2 className="lock__text">
            Upgrade to a <span className="pre">Premium</span> account to access this content
            </h2>
          </div>
        )}
      </div>
      <div className="buttons__container">
        <a className="btn__wrapper" href="/section-6/lesson-4">
          <button className="react__btn next__lesson--btn">
            Proceed to Lesson 4
          </button>
        </a>
        <a
          className="btn__wrapper"
          target="_blank"
          href="https://discord.gg/P8hxsbzsKS"
        >
          <button className="help__btn next__lesson--btn">Need Help?</button>
        </a>
      </div>
    </div>
  );
};

export default ReactLesson3;
